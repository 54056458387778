import "./404.css";

const NotFound = () => {

    return (
        <div className="divcenter1">
            <div className="periexomena">
                <h1>Error <em>404</em> Page Not Found</h1>
                <h2>Use the footer below to navigate elsewhere</h2>
            </div>
        </div>
    );
}
 
export default NotFound; 
